// import React, { createContext, useState, useContext } from 'react';

// const CartContext = createContext();

// export const useCart = () => useContext(CartContext);

// export const CartProvider = ({ children }) => {
//   const [cart, setCart] = useState([]);
//   const [isCartOpen, setIsCartOpen] = useState(false);
//   const handleActionClick = (selectedGift) => {
//     const isGiftInCart = cart.some(gift => gift.id === selectedGift.id);

//     if (isGiftInCart) {
//       setCart(cart.filter(gift => gift.id !== selectedGift.id));
//     } else {
//       setCart([...cart, selectedGift]);
//     }
//   };

//   const totalPrice = cart.reduce((total, gift) => total + gift.price, 0).toFixed(2);

//   const handleClearCart = () => setCart([]);
//   const addToCart = (item) => setCart((prevCart) => [...prevCart, item]);
//   const toggleCartDrawer = () => setIsCartOpen(!isCartOpen);

//   return (
//     // <CartContext.Provider value={{ cart, totalPrice, handleClearCart, addToCart, isCartOpen, toggleCartDrawer }}>
//     //   {children}
//     // </CartContext.Provider>

// <CartContext.Provider value={{ cart, handleActionClick, handleClearCart, totalPrice }}>
// {children}
// </CartContext.Provider>
//   );
// };

// import React, { createContext, useState, useContext } from 'react';
// import productList from '../GiftList/items/ProductList';

// const CartContext = createContext();

// export const useCart = () => useContext(CartContext);

// export const CartProvider = ({ children }) => {
//   const [gifts, setGifts] = useState(productList || []);
//   const [cart, setCart] = useState([]);

//   const handleClearCart = () => {
//     setCart([]);
//     setGifts(gifts.map(gift => ({ ...gift, available: true, buyer: null, status: false })));
//   };

//   const handleActionClick = (giftId) => {
//     const selectedGift = gifts.find(gift => gift.id === giftId);
//     const isGiftInCart = cart.some(gift => gift.id === giftId);

//     if (selectedGift) {
//       if (isGiftInCart) {
//         setCart(cart.filter(gift => gift.id !== giftId));
//         setGifts(gifts.map(gift =>
//           gift.id === giftId ? { ...gift, status: !gift.status } : gift
//         ));
//       } else {
//         setCart([...cart, selectedGift]);
//         setGifts(gifts.map(gift =>
//           gift.id === giftId ? { ...gift, status: !gift.status } : gift
//         ));
//       }
//     }
//   };
  

//   const totalPrice = cart.reduce((total, gift) => total + gift.price, 0).toFixed(2);

//   return (
//     <CartContext.Provider value={{ cart, totalPrice, gifts, handleActionClick, handleClearCart }}>
//       {children}
//     </CartContext.Provider>
//   );
// };


//// Workig great
// import React, { createContext, useState, useContext } from 'react';
// import productList from '../GiftList/items/ProductList';

// const CartContext = createContext();

// export const useCart = () => useContext(CartContext);

// export const CartProvider = ({ children }) => {
//   const [gifts, setGifts] = useState(productList || []);
//   const [cart, setCart] = useState([]);
//   const [isCartOpen, setIsCartOpen] = useState(false); 

//   const handleClearCart = () => {
//     setCart([]);
//     setGifts(gifts.map(gift => ({ ...gift, available: true, buyer: null, status: false })));
//   };

//   // const handleActionClick = (giftId) => {
//   //   const selectedGift = gifts.find(gift => gift.id === giftId);
//   //   const isGiftInCart = cart.some(gift => gift.id === giftId);

//   //   if (selectedGift) {
//   //     if (isGiftInCart) {
//   //       setCart(cart.filter(gift => gift.id !== giftId));
//   //       setGifts(gifts.map(gift =>
//   //         gift.id === giftId ? { ...gift, status: !gift.status } : gift
//   //       ));
//   //     } else {
//   //       setCart([...cart, selectedGift]);
//   //       setGifts(gifts.map(gift =>
//   //         gift.id === giftId ? { ...gift, status: !gift.status } : gift
//   //       ));
//   //     }
//   //   }
//   // };

//   const handleActionClick = (giftId) => {
//     setGifts(prevGifts => {
//       const selectedGift = prevGifts.find(gift => gift.id === giftId);
//       const isGiftInCart = cart.some(gift => gift.id === giftId);

//       if (!selectedGift) return prevGifts;

//       setCart(prevCart => {
//         if (isGiftInCart) {
//           return prevCart.filter(gift => gift.id !== giftId); // Remove from cart
//         } else {
//           return [...prevCart, selectedGift]; // Add to cart
//         }
//       });

//       return prevGifts.map(gift =>
//         gift.id === giftId ? { ...gift, status: !gift.status } : gift
//       );
//     });
//   };

//   const toggleCartDrawer = () => {

//     // console.log("toggleCartDrawer called, current isCartOpen:", isCartOpen);
//     // setIsCartOpen(!isCartOpen);
//     // console.log("New isCartOpen value:", !isCartOpen);
    
//     setIsCartOpen((prevState) => {
//       console.log("toggleCartDrawer called, current isCartOpen:", prevState);
//       const newState = !prevState;
//       console.log("New isCartOpen value:", newState);
//       return newState;
//     });
//   };

//   const totalPrice = cart.reduce((total, gift) => total + gift.price, 0).toFixed(2);

//   return (
//     <CartContext.Provider value={{ cart, totalPrice, gifts, handleActionClick, handleClearCart, isCartOpen, toggleCartDrawer }}>
//       {children}
//     </CartContext.Provider>
//   );
// };


import React, { createContext, useState, useContext } from 'react';
import productList from '../GiftList/items/ProductList';

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [gifts, setGifts] = useState(productList);
  const [cart, setCart] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);

  const addToCart = (item) => {
    setCart((prevCart) => [...prevCart, item]);
    setGifts((prevGifts) => prevGifts.map(gift => 
      gift.id === item.id ? { ...gift, status: true } : gift
    ));
  };

  const removeFromCart = (itemId) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== itemId));
    setGifts((prevGifts) => prevGifts.map(gift => 
      gift.id === itemId ? { ...gift, status: false } : gift
    ));
  };

  const clearCart = () => {
    setCart([]);
    setGifts((prevGifts) => prevGifts.map(gift => ({ ...gift, status: false })));
  };

  const toggleCartDrawer = () => {
    setIsCartOpen((prevState) => !prevState);
  };

  const totalPrice = cart.reduce((total, item) => total + item.price, 0).toFixed(2);

  const handleActionClick = (giftId) => {
    const selectedGift = gifts.find(gift => gift.id === giftId);
    const isGiftInCart = cart.some(gift => gift.id === giftId);

    if (selectedGift) {
      if (isGiftInCart) {
        removeFromCart(giftId);
      } else {
        addToCart(selectedGift);
      }
    }
  };

  return (
    <CartContext.Provider value={{ 
      gifts,
      cart, 
      addToCart, 
      removeFromCart, 
      clearCart, 
      isCartOpen, 
      toggleCartDrawer, 
      totalPrice,
      handleActionClick
    }}>
      {children}
    </CartContext.Provider>
  );
};