import { createTheme } from '@mui/material/styles';

const Theme = createTheme({
    palette: {
      background: {
        default: '#F7F4EF', // light beige background color
      },
      primary: {
        main: '#6F9479', // green similar to the text color
      },
      secondary: {
        main: '#9F8F8F', // soft gray for secondary elements
      },
      text: {
        primary: '#4F4F4F', // darker gray for the main text
        secondary: '#9F8F8F', // matching gray for secondary text
      },
    },
    typography: {
      // fontFamily: "'Dancing Script', cursive", // Elegant font similar to the handwritten style
      fontFamily: "'Goudy Bookletter 1911', serif",
      h1: {
        fontFamily: "'Dancing Script', cursive",
        fontSize: '3rem',
        fontWeight: 400,
        color: '#6F9479', // Green for headings
      },
      h2: {
        fontFamily: "'Dancing Script', cursive",
        fontSize: '2rem',
        fontWeight: 400,
        color: '#4F4F4F', // Gray for sub-headings
      },
      body1: {
        fontSize: '1.25rem',
        color: '#4F4F4F', // Default text color
      },
    },
    // shape: {
    //   borderRadius: 8, // Set default border-radius
    // },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: '#F7F0E9',
            // backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0.2, 0, 0, 0.3)), url(${backgroundImage2})`,
            // backgroundImage: `url(${backgroundImage2})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          },
        },
  
      },
      MuiButton: {
        defaultProps: {
          // Make sure all buttons use the defined border radius
          disableElevation: true, // Optional: disables the button elevation
        },
        styleOverrides: {
          root: {
            borderRadius: 8, // Set border-radius for buttons
          },
        },
      },
    },
  });


export default Theme;