import React from "react";
import CartDrawer from "../CartDrawer/CartDrawer";
import CartButton from "../CartButton";

const CartSummary = () => {
  return (
    <>
      <CartButton />
      <CartDrawer />
    </>
  );
};

export default CartSummary;
