import React from 'react';
// import GiftList from '../components/GiftList/GiftList';
import GiftListWithProviders from '../components/GiftList/GiftList';


const Gifts = () => {
  return (
    <GiftListWithProviders />
);
};

export default Gifts;