import React from "react";
import { Box, Modal, Alert, Typography, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const PixModal = ({ openPix, handlePixClose }) => {
  return (
    <Modal open={openPix} onClose={handlePixClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handlePixClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Alert severity="error" sx={{ textAlign: "center" }}>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "'Roboto', sans-serif",
              fontWeight: "bold",
              fontSize: "1.1rem",
              color: "#D32F2F",
            }}
          >
            Por favor, faça o pagamento pro nosso PIX
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontFamily: "'Roboto', sans-serif",
              fontWeight: "bold",
              fontSize: "1.1rem",
              color: "#ab0a0a",
            }}
          >
            <strong>casamento@yakeshi.com.br</strong>
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontFamily: "'Roboto', sans-serif",
              fontWeight: "bold",
              fontSize: "1.0rem",
              color: "#D32F2F",
            }}
          >
            no Nubank.
          </Typography>
        </Alert>
      </Box>
    </Modal>
  );
};

export default PixModal;
