import React from 'react';
import { Typography, Box, Container } from '@mui/material';
// import IconLabelButtons from '../components/severalButtons';
// import Countdown from '../components/Countdown/Countdown';
import FormsButton from '../components/FormsButton/FormsButton';



const Home = () => {
  

  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4, textAlign: 'center' }}>
        <Typography variant="h2" component="h1" gutterBottom>
          Foi incrível
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          Setembro 28, 2024
        </Typography>

        <Typography variant="body1" sx={{ mt: 2 }}>
          Precisamos da sua ajudinha rápida!
        </Typography>

        <FormsButton/>
      </Box>
    </Container>
  );
};



export default Home;