import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import GiftRow from "./GiftRow";

const GiftTable = ({ gifts, handleActionClick }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <TableContainer sx={{ maxHeight: isSmallScreen ? "85vh" : "75vh", overflowY: "auto" }}>
      <Table stickyHeader sx={{ fontSize: isSmallScreen ? '1.2em' : '1.5em' }}>
        <TableHead>
          <TableRow sx={{ fontFamily: '"Goudy Bookletter 1911", serif', fontSize: isSmallScreen ? '1.2em' : '1.5em' }}>
            <TableCell sx={{ fontFamily: '"Goudy Bookletter 1911", serif', fontSize: '0.5em', padding: isSmallScreen ? '8px' : '16px' }}>Imagem</TableCell>
            <TableCell sx={{ fontFamily: '"Goudy Bookletter 1911", serif', fontSize: '0.5em', padding: isSmallScreen ? '8px' : '16px' }}>Produto</TableCell>
            <TableCell sx={{ fontFamily: '"Goudy Bookletter 1911", serif', fontSize: '0.5em', padding: isSmallScreen ? '8px' : '16px' }}>Preço</TableCell>
            <TableCell sx={{ fontFamily: '"Goudy Bookletter 1911", serif', fontSize: '0.5em', padding: isSmallScreen ? '8px' : '16px' }}>Selecionar</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {gifts.map((product) => (
            <GiftRow
              key={product.id}
              product={product}
              handleActionClick={handleActionClick}
              isSmallScreen={isSmallScreen}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GiftTable;
