import React, { useState } from 'react';
import { TableRow, TableCell, Card, CardMedia, Typography, IconButton, Modal, Box, useMediaQuery, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const GiftRow = ({ product, handleActionClick, isSmallScreen }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
    <TableRow key={product.id}>
      <TableCell sx={{ cursor: 'pointer', padding: isSmallScreen ? '8px' : '16px' }} onClick={handleOpen}>
        <Card>
          <CardMedia
            component="img"
            height={isSmallScreen ? "80" : "100"}
            image={product.image}
            alt={product.title}
          />
        </Card>
      </TableCell>
      <TableCell sx={{ 
        maxWidth: isSmallScreen ? 120 : 200, 
        wordWrap: 'break-word', 
        whiteSpace: 'normal',
        padding: isSmallScreen ? '8px' : '16px'
      }}>
        <Typography variant={isSmallScreen ? "body2" : "body1"}>{product.title}</Typography>
      </TableCell>
      <TableCell sx={{ padding: isSmallScreen ? '8px' : '16px' }}>
        <Typography variant={isSmallScreen ? "body2" : "body1"} fontFamily="Roboto">{product.price}</Typography>
      </TableCell>
      <TableCell sx={{ padding: isSmallScreen ? '8px' : '16px' }}>
        <IconButton
          color={product.status ? 'secondary' : 'primary'}
          onClick={() => handleActionClick(product.id)}
          size={isSmallScreen ? "small" : "medium"}
        >
          {product.status ? <DeleteIcon /> : <AddIcon />}
        </IconButton>
      </TableCell>
    </TableRow>

    <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backdropFilter: 'blur(5px)',
        }}
      >
        <Box
          sx={{
            outline: 'none',
            maxWidth: isMobile ? '95vw' : '90vw',
            maxHeight: isMobile ? '95vh' : '90vh',
            backgroundColor: 'background.paper',
            borderRadius: '18px',
            padding: '16px',
            boxShadow: 24,
          }}
        >
          <img
            src={product.image}
            alt={product.title}
            style={{
              width: '100%',
              height: 'auto',
              cursor: 'pointer',
              borderRadius: '8px',
            }}
            onClick={handleClose}
          />
          <Typography variant="h6" sx={{ mt: 2, textAlign: 'center' }}>
            {product.title}
          </Typography>
          <Typography variant="body1" sx={{ mt: 1, textAlign: 'center' }}>
            {product.price}
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default GiftRow;
