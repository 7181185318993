import React from 'react';
import { TableContainer, Typography, Box, Pagination, useMediaQuery, useTheme } from '@mui/material';
import GiftTable from './GiftTable';
import CartSummary from '../CartSummary/CartSummary';
import SwipeableViews from 'react-swipeable-views';

import { PaginationProvider, usePagination } from '../Contexts/PaginationContext';
import { CartProvider, useCart } from '../Cart/CartContext';

const GiftList = () => {
  const { gifts, handleActionClick } = useCart();
  const { currentPage, itemsPerPage, handlePageChange } = usePagination();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const totalPages = Math.ceil(gifts.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedGifts = gifts.slice(startIndex, startIndex + itemsPerPage);

  const handleSwipe = (index) => {
    if (isMobile) {
      handlePageChange(null, index + 1);
      console.log(`Swiped to page ${index + 1}`);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Box sx={{ display: 'flex'
        , justifyContent: 'space-between'
        , alignItems: 'center'
        , p: 2
        , borderBottom: '1px solid #e0e0e0' }}>
        <Typography variant={isMobile ? "h5" : "h4"}>Presentes disponíveis</Typography>
        <Box sx={{ marginRight: '4px' }}>
          <CartSummary />
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, overflow: 'hidden', p: 2, position: 'relative' }}>
        {isMobile ? (
          <SwipeableViews
            index={currentPage - 1}
            onChangeIndex={handleSwipe}
            enableMouseEvents
          >
            {Array.from({ length: totalPages }, (_, i) => (
              <TableContainer key={i}>
                <GiftTable 
                  gifts={gifts.slice(i * itemsPerPage, (i + 1) * itemsPerPage)} 
                  handleActionClick={handleActionClick} 
                  isSmallScreen={isSmallScreen}
                />
              </TableContainer>
            ))}
          </SwipeableViews>
        ) : (
          <TableContainer>
            <GiftTable 
              gifts={paginatedGifts} 
              handleActionClick={handleActionClick} 
              isSmallScreen={isSmallScreen}
            />
          </TableContainer>
        )}
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 2,
          position: isMobile ? 'sticky' : 'static',
          bottom: isMobile ? 0 : 'auto',
          left: 0,
          right: 0,
          backgroundColor: 'background.paper',
          padding: isMobile ? '10px' : 0,
          boxShadow: isMobile ? '0px -2px 4px rgba(0,0,0,0.1)' : 'none',
          zIndex: 1,
        }}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            size={isMobile ? "small" : "medium"}
            showFirstButton
            showLastButton
            siblingCount={isMobile ? 0 : 1}
            boundaryCount={1}
          />
        </Box>
      </Box>
      {isMobile && (
        <Box sx={{
          position: 'fixed',
          bottom: '70px',
          right: '20px',
          backgroundColor: 'primary.main',
          color: 'white',
          borderRadius: '50%',
          width: '60px',
          height: '60px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
        }}>
          <Typography variant="body1">{`${currentPage}/${totalPages}`}</Typography>
        </Box>
      )}
    </Box>
  );
};

const GiftListWithProviders = () => (
  <CartProvider>
    <PaginationProvider>
      <GiftList />
    </PaginationProvider>
  </CartProvider>
);

export default GiftListWithProviders;
