import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// import Home from "./pages/Home";
import Feedback from "./pages/Feedback";
import Gifts from "./pages/Gifts";
// import Navbar from "./components/Navbar";
import NavbarFeedback from "./components/Navbar/NavbarFeedback";
import Theme from "./components/Theme";

import CartDrawer from "./components/CartDrawer/CartDrawer";
import { CartProvider } from "./components/Cart/CartContext";
// import { PaginationProvider } from "./components/Contexts/PaginationContext";

function RedirectHandler() {
  const navigate = useNavigate();

  useEffect(() => {
    const redirect = sessionStorage.getItem('redirect');
    if (redirect) {
      sessionStorage.removeItem('redirect');
      navigate(redirect);
    }
  }, [navigate]);

  return null;
}

function NotFound() {
  return <h1>404 - Page Not Found</h1>;
}

function App() {
  return (
    <CartProvider>
      {/* <usePagination> */}
        <BrowserRouter>
          <ThemeProvider theme={Theme}>
            <CssBaseline />
            <NavbarFeedback />
            <CartDrawer />
            <RedirectHandler />
            <Routes>
              <Route path="/" element={<Feedback />} />
              <Route path="/gifts" element={<Gifts />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </ThemeProvider>
        </BrowserRouter>
      {/* </usePagination> */}
    </CartProvider>
  );
}

export default App;
