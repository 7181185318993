import React, { useState } from "react";
import { Drawer, Box, Typography, Button, List, ListItem, ListItemText, Stepper, Step, StepLabel } from '@mui/material';
import { useCart } from '../Cart/CartContext';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PixModal from "../CartSummary/PixModal";
import CreditCardModal from "../CartSummary/CreditCardModal";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PixIcon from '@mui/icons-material/Pix';
import CreditCardIcon from '@mui/icons-material/CreditCard';

const CartDrawer = () => {
  const { cart, totalPrice, clearCart, removeFromCart, isCartOpen, toggleCartDrawer } = useCart();

  const [openPix, setOpenPix] = useState(false);
  const [openCC, setOpenCC] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const handlePixOpen = () => setOpenPix(true);
  const handlePixClose = () => setOpenPix(false);

  const handleCCOpen = () => setOpenCC(true);
  const handleCCClose = () => setOpenCC(false);

  const iCaseiStore = "https://sites.icasei.com.br/yakeshi/pages/33411423";
  const handleRedirectToCC = () => {
    window.open(iCaseiStore, "_blank", "noopener,noreferrer");
  };

  const steps = ['Revisar Carrinho', 'Escolher Pagamento', 'Confirmar'];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFinalize = () => {
    // Add any finalization logic here
    toggleCartDrawer(); // Close the cart
  };

  return (
    <Drawer anchor="right" open={isCartOpen} onClose={toggleCartDrawer}>
      <Box sx={{ width: 400, p: 3, position: 'relative', bgcolor: 'background.default', height: '100%', display: 'flex', flexDirection: 'column' }}>
        <IconButton
          aria-label="close"
          onClick={toggleCartDrawer}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" sx={{ fontFamily: "'Roboto', sans-serif", fontWeight: 'bold', mb: 2 }}>Checkout</Typography>
        
        <Stepper activeStep={activeStep} sx={{ mb: 3 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && (
          <>
            <Typography variant="h6" sx={{ fontFamily: "'Roboto', sans-serif", fontWeight: 'bold', mb: 2 }}>Total: R$ {totalPrice}</Typography>
            {cart.length === 0 ? (
              <Typography variant="body1" sx={{ fontFamily: "'Roboto', sans-serif", color: 'text.secondary' }}>Ainda não selecionou presentes.</Typography>
            ) : (
              <List sx={{ flexGrow: 1, overflowY: 'auto' }}>
                {cart.map((item) => (
                  <ListItem key={item.id} sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center', mb: 1 }}>
                    <img src={item.image} alt={item.name} width="50" style={{ borderRadius: "6px" }} />
                    <ListItemText primary={item.name} secondary={`R$ ${item.price}`} sx={{ ml: 2 }} />
                    <IconButton onClick={() => removeFromCart(item.id)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            )}
            <Button
              variant="contained"
              color="error"
              sx={{ textTransform: 'none', fontFamily: "'Roboto', sans-serif", mt: 2, width: '100%' }}
              startIcon={<ShoppingCartIcon />}
              onClick={clearCart}
            >
              Remover Todos
            </Button>
          </>
        )}

        {activeStep === 1 && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 3, alignItems: 'center' }}>
            <Typography variant="h6" sx={{ fontFamily: "'Roboto', sans-serif", mb: 2 }}>Escolha o método de pagamento:</Typography>
            <Typography variant="h6" sx={{ fontFamily: "'Roboto', sans-serif", fontWeight: 'bold', mb: 2 }}>Total: R$ {totalPrice}</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePixOpen}
              sx={{ 
                fontFamily: "'Roboto', sans-serif'", 
                width: '200px', 
                height: '60px', 
                display: 'flex', 
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <PixIcon sx={{ fontSize: '2rem', color: 'white', mr: 1 }} />
              <Typography sx={{ color: 'white', fontSize: '16px' }}>Pagar com PIX</Typography>
            </Button>

            <Button
              variant="contained"
              color="secondary"
              onClick={handleCCOpen}
              sx={{ 
                fontFamily: "'Roboto', sans-serif", 
                width: '200px', 
                height: '60px', 
                display: 'flex', 
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <CreditCardIcon sx={{ fontSize: '2rem', color: 'white', mr: 1 }} />
              <Typography sx={{ color: 'white', fontSize: '16px' }}>Pagar com Cartão</Typography>
            </Button>
          </Box>
        )}

        {activeStep === 2 && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 3 }}>
            <Typography variant="h6" sx={{ fontFamily: "'Roboto', sans-serif", mb: 2 }}>UAU! Ficamos muito felizes em poder contar com você!
              Pode nos enviar o comprovante no nosso email?
              </Typography>
            <Typography variant="body1" sx={{ fontFamily: "'Roboto', sans-serif", mb: 2 }}>casamento@yakeshi.com.br</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleFinalize}
              sx={{ 
                fontFamily: "'Roboto', sans-serif", 
                width: '200px', 
                height: '60px', 
                mt: 2
              }}
            >
              Finalizar
            </Button>
          </Box>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 'auto', pt: 2 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Voltar
          </Button>
          <Button onClick={handleNext} disabled={activeStep === steps.length - 1}>
            {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
          </Button>
        </Box>

        <PixModal openPix={openPix} handlePixClose={handlePixClose} />
        <CreditCardModal
          openCC={openCC}
          handleCCClose={handleCCClose}
          handleRedirectToCC={handleRedirectToCC}
        />
      </Box>
    </Drawer>
  )
};
export default CartDrawer;