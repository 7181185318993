import React from "react";
import { Box, Modal, Alert, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const CreditCardModal = ({ openCC, handleCCClose, handleRedirectToCC }) => {
  return (
    <Modal open={openCC} onClose={handleCCClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          textAlign: "center",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleCCClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Alert severity="warning" sx={{ textAlign: "center" }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontFamily: "'Roboto', sans-serif",
            }}
          >
            Você tem certeza que quer pagar com cartão?
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mb: 2,
              fontFamily: "'Roboto', sans-serif",
              fontSize: "1.0rem",
            }}
          >
            Se sim, terá de iniciar novamente a seleção dos presentes
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleRedirectToCC}
            sx={{
              fontFamily: "'Roboto', sans-serif",
              fontSize: "1.0rem",
            }}
          >
            Pagar com Cartão de Crédito
          </Button>
        </Alert>
      </Box>
    </Modal>
  );
};

export default CreditCardModal;
