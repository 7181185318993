const notShuffledProductList = [
  // {
  //   id: 135281,
  //   title: "Garanta o jantar do noivo no 1.º dia de casados",
  //   image:
  //     "https://lh3.googleusercontent.com/E5xJAPsIXrn7PRnOgQGlJGVT2k9S5sQmYpdNBsznMiJ73T2siGBvsR_e39Nf8ggap_e2pRc23ye9LuawWKBvr86_wFcgIOJPyyZ9OiH5GP-X=s0",
  //   price: 130.21,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // {
  //   id: 1711,
  //   title: "Ligação direta internacional",
  //   image:
  //     "https://lh3.googleusercontent.com/FWbugYF0mjVi8C8Q7TG0pCKVNq_a93nbqFUOcaVgJbw3ipUa4BQVIgZ-ZoCJWET7j8GxC67zxx9wtmY1oFQUqau7YqdNiPqPlCWStskeJXo=s0",
  //   price: 131.35,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // {
  //   id: 1809175,
  //   title: "Luminaria Office Focus Branco E27 38cm",
  //   image:
  //     "https://lh3.googleusercontent.com/1gxxeN3bLpsgPHJNw0ro5EPTWeMDPlLGmYj91jWijBdykXOvmgxJjsak9kFRtKwSsNe-LZoEBc7CozK5mhSUq-ADKuilBYDl6XTMBmDlEvM=s0",
  //   price: 131.36,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // {
  //   id: 355263,
  //   title: "Conjunto Sousplat Inox 4 Peças 33cm: Euro Home",
  //   image:
  //     "https://lh3.googleusercontent.com/kKg4bSr9zilamYnzBHfQRcqKXqTQZvaFeX1SzW-r9VYP_klo8r1C-5gANBTpSyM5ChU5R4ulCVOwb3e6rDk6oZXr1uj24bOhAvE7ffO2JP6V=s0",
  //   price: 141.65,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // {
  //   id: 135275,
  //   title: "Curso de culinária para noivo",
  //   image:
  //     "https://lh3.googleusercontent.com/IMTNvWGEZQORj6Nllte7v6LXeFRebURZAP_qsg4I5CpmXHFYMGVs2FeVj5ZBEPz708N2t1hFvVjMRPXd4s7TYeH8RD5w4wtYShawzs78Y34=s0",
  //   price: 150.81,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // {
  //   id: 2469594,
  //   title: "Cadeira de escritório",
  //   image:
  //     "https://lh3.googleusercontent.com/21cs8Vtwt8NGyfxckHQr2KzPX0R58CEBJyE-XzhkL-8aKV5IC8Z-6Vu_FhimCtGIEmYCCng5Z2YnqsKYrHJAv21FpkAK7eD79uzGPv1RKr0q=s0",
  //   price: 166.83,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // {
  //   id: 249751,
  //   title: "Conjunto de sousplat",
  //   image:
  //     "https://lh3.googleusercontent.com/9GwRLOyMWj0oZLSpSP4r8SPG7VgOjhiGw1ipWsnx5YmWTJlEwm7V8h-a3cSNXYgl9yANKDO3kEfjxSJT3N4w1YRzB9jN3tOo0rmGkaKWMmw=s0",
  //   price: 166.93,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // {
  //   id: 244915,
  //   title: "Decanter",
  //   image:
  //     "https://lh3.googleusercontent.com/xJKpCWpCNqPWOFDHaBjVGHvArn5UC6ZCWXDK85lVgwNCeRzTlCv9-1v88jYt3QaIq9Dnb6XvtUYEjNCigrIr-9iLppviEsIFOyR1_j9JTdQ=s0",
  //   price: 166.97,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // // --2
  // {
  //   id: 2482466,
  //   title: "Jogo de banho: 5 peças",
  //   image:
  //     "https://lh3.googleusercontent.com/SEDxWurhLIQqig-mTnrtnLT9ezFSG2WD9GesdGJDYHh9228wEzw3s0AvFAgOquISLKdYQCqP2vpb2DuyLFU3Nvb5dEaxGLY7gQVfpddgpU8B=s0",
  //   price: 167.98,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  {
    id: 249741,
    title: "Conjunto para comida oriental",
    image:
      "https://lh3.googleusercontent.com/5oBIvd6qIkSdTevs7iSNyqWs4gOYLNGnKqCdFMSL5EpRw5M-l13Aegb5NFpf5Kv12LnjmqN81qM_D06MmIbhTsRnBod338mUUrTldCtxxHCb=s0",
    price: 179.42,
    purchased: false,
    status: false,
    buyer: null,
  },
  {
    id: 1819840,
    title: "Faqueiro 24 peças",
    image:
      "https://lh3.googleusercontent.com/20ZEzLcZ1CKgLBLWlWFAzxxRwZkevyWc8exmRLSrpm9H2bXs_vCgVDC1BVCRYB7NsaLGDC8swXo9s3WpoQ40A4jVhXFTgAvdkEJjvrXQFz37=s0",
    price: 201.17,
    purchased: false,
    status: false,
    buyer: null,
  },
  // {
  //   id: 245682,
  //   title: "Taças para martini: 6 peças",
  //   image:
  //     "https://lh3.googleusercontent.com/GWvEmQmd-NYq3F_8aTCPXmgNHXtIJtDTEFCsbbtOOv4cSDiha4H9xI2nxumkMZc1Lfk66B8FZxDt5i5dIphpvgRBdJh_GL4YTXtXQ1erPL9o=s0",
  //   price: 204.63,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // {
  //   id: 1813829,
  //   title: "Conjunto de Taças para Vinho",
  //   image:
  //     "https://lh3.googleusercontent.com/4yD_g6Sov8S7a1ZfL87fw2_cXo9WylWgyM2igXTVSXvSheQmztAomQuF62wgQQ8cCQ-xqADHfQBsVAJMQp4lCimT9vpKtFdIE82IGcu1u_q9=s0",
  //   price: 230.93,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // {
  //   id: 239512,
  //   title: "Copos de cristal para cerveja: 6 peças",
  //   image:
  //     "https://lh3.googleusercontent.com/7AB2t8S6j8OEjDXRK79o-gfzUI2kaU13nRGVRyUlv3XrLNdlQbOTIu-MdcBfBUtSCiBtVBTR6_Qn56k7YsbmOkiTi8Zbc9_2jwfYTrP8hWU=s0",
  //   price: 231.37,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // {
  //   id: 254548,
  //   title: "Jogo de baixelas inox",
  //   image:
  //     "https://lh3.googleusercontent.com/c8UKHnrX9qU3ZLjJFlhy78dtOsUJwRTd-o3wYWK35t3u3uyfilVOpTeGUqD09G_N5tQ7WnLr29ym19Hb4uPdYrTf25avuBPWs05RQjPof4LQ=s0",
  //   price: 233.43,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // {
  //   id: 1813867,
  //   title: "Jogo De 6 Taças Para Sobremesa Cristal",
  //   image:
  //     "https://lh3.googleusercontent.com/x6wXBI1Soeyx12lDt2md30OV29Tux4mcWaYBuKf9jkiv46lBZR44yh2P-x5hDi39mPnecU9909PpHrPVjZEMaPJJuLvk5rNRlwRFRVEC8Egi=s0",
  //   price: 234.36,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // // -- 3
  // {
  //   id: 1814789,
  //   title: "Liquidificador Oster Maximum com jarra de vidro 450 W",
  //   image:
  //     "https://lh3.googleusercontent.com/pmNRT_HFz3-f9FSOnyElABmLiCMFcAQYu9ChKNjZe1r76IJgE3UkmJ9jbE6iJcdByJhimY1m5mkRrZG4gZT75JQynzMQJ5kVdrhN9VvJ58iq=s0",
  //   price: 244.66,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // {
  //   id: 1817508,
  //   title: "Sapateira para parar com a bagunça de sapatos da sala.",
  //   image:
  //     "https://lh3.googleusercontent.com/ZWMByKRC39wwFng6tarvgNGGcB4SI6ggTbd1PweetdZnzQ1ZRKQ51eIdc9Ud9fJiLwptwwnUuVqr1FeP4SQi5_dWoZQK2CaL5JfNoA7Mk3he=s0",
  //   price: 245.8,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // {
  //   id: 1813863,
  //   title: "Cafeteira Italiana Bialetti Moka: 6 Xícaras",
  //   image:
  //     "https://lh3.googleusercontent.com/Wkbai3XVojvCT5BAYtaJPK1yrjTCx59r9N22h8gjExWM5TG02zJ9WPL-XlphVo8tNSywUQ8JTnwQmVVifrZxEH0rWuMAUNlftcwYyEy6R58=s0",
  //   price: 257.25,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // {
  //   id: 2482085,
  //   title: "Cadeira para escritório",
  //   image:
  //     "https://lh3.googleusercontent.com/4Yae6j6e_250lozJfXhmXw93HujtIpmjaRdfQ8CSFZrTHsA7WvX7nFVAohp072-f14c4Ltt4vcCzh_AE5hKFj46AeiJdxCi_iFMZdR1jAtgSQA=s0",
  //   price: 268.58,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // {
  //   id: 249759,
  //   title: "Jogo para torta",
  //   image:
  //     "https://lh3.googleusercontent.com/S25vC-5Qah8m7YnBa56quTlpAmJj68o4JZoWwOrFynlpmQ-_z9YztlfTx6Vs8q8XshjABuwqZqNS_KhPBlD2iEr8qvGoacWVr_hNxjZub3O7RA=s0",
  //   price: 280.14,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  {
    id: 1815036,
    title: "Jogo Aparelho de Jantar 42 peças",
    image:
      "https://lh3.googleusercontent.com/Fm43OAP3jAm69AEoa_2dz4OisOLoGVIn5fABrD9V0C0zCgF1RkeNGTnlEKai09mdUYx9vzHPvt6bHzUD8eQn9Hg-vT2K0-9nDe5cvbw_s4N8-w=s0",
    price: 291.13,
    purchased: false,
    status: false,
    buyer: null,
  },
  {
    id: 2128519,
    title: "Aparelho de jantar",
    image:
      "https://lh3.googleusercontent.com/SV93cQabkNYCFzDPrTkoAi-NcFKBSRX-OrMElSoFocCc7ZiWMI5ssrtQFhAhZcTvdwyBV-Gk0Y-VGhIvkF-YOAdzMkq8trC-c-kLK8CFbLSP=s0",
    price: 298.34,
    purchased: false,
    status: false,
    buyer: null,
  },
  {
    id: 4972,
    title: "Cura da ressaca para o noivo",
    image:
      "https://lh3.googleusercontent.com/LrddjVQ6qkaUvi4VGWKjUIR3RwXPSbu7YCbldRilFLEyA101mmq55327fm20sdLiLwLfBikDIp_VQxIJG1DTc7GpCwadJIxWHglhNKnEIBu7=s0",
    price: 300.74,
    purchased: false,
    status: false,
    buyer: null,
  },
  // --4
  {
    id: 1813856,
    title: "Saladeira Com Molheira Andrea 5 Peças",
    image:
      "https://lh3.googleusercontent.com/gklJt6hdV8xGeazC6h2HnsZF-jUOz6KcseIiT-8XfCL8sz-8P9gKS75J0EGqyI5k2JyXtbct_w6rJCBovhG5RSpX0AfmvR8Pb4ruATzzmsw=s0",
    price: 313.33,
    purchased: false,
    status: false,
    buyer: null,
  },
  // {
  //   id: 1813869,
  //   title: "Petisqueira Giratória 9 Divisões Atlânta Bambú",
  //   image:
  //     "https://lh3.googleusercontent.com/H6gX8jc9V8AHWu-DBHUvpt_CIRAEzxX8AXHBVCHsTls6rdFVlzO8vDlkqk5-eTu20mjAsq0zdIWG-Lq0xzUjNkNSbGVi-LjnsqAlIXyQqw8faw=s0",
  //   price: 316.76,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  {
    id: 131600,
    title: "Massagem relaxante para o noivo depois de ver a conta do casamento",
    image:
      "https://lh3.googleusercontent.com/hf8MsgxUYWJXaP_7oFMoWzMdC4Wy1wQIHDRd1tYbKizPWl3qhvDFnQAnxGoRwnlLzpfo63VuA43oaElHu18Y9NJv-ssvJNYQu_rFvXY_y7g=s0",
    price: 387.64,
    purchased: false,
    status: false,
    buyer: null,
  },
  // {
  //   id: 252154,
  //   title: "Taças de champanhe: 12 peças",
  //   image:
  //     "https://lh3.googleusercontent.com/gLgpqFakat17fud9KCXvX_q2fM6yUs-DAW_mF6WWtep2AOrKUfKA5fKdxf9CyMb68Vu2THNhrL0zIxHpGKuAVvgcaLM_EjlDuuFfd4QMNKg=s0",
  //   price: 333.93,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // {
  //   id: 1806913,
  //   title: "Processador de Alimentos",
  //   image:
  //     "https://lh3.googleusercontent.com/2VW3PO7Qqm0nUswRxkOzfOcT2zUaXGavjgDhSLD7aSFKZh0F0jzrYV6OQ2iOR7T4WA_BPe-nSGY-53k9gxZlhTj45u4MWwv0wN28Xc9CVbdi=s0",
  //   price: 348.81,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // {
  //   id: 2669941,
  //   title: "Jogo de faqueiro Tramontina",
  //   image:
  //     "https://lh3.googleusercontent.com/fhPLMQp4f-Q_rpjDAIdIoHiI7OmCXNkeUWvLkV88HzgVZVLombsErRzVDKBCdqPw28nH4Scx4kTiDJxVCrMvuG1TEFh0q9RFmOS8Ah23sts8=s0",
  //   price: 360.26,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // {
  //   id: 79638,
  //   title: "Porta retrato digital",
  //   image:
  //     "https://lh3.googleusercontent.com/yYEl674dOl4hTjJBHX4jV5qD4DfUHayGV99Kt2y5R7wLDaf7DCraqAJnddh887tNn92sAV8w7hdjB0YRjA72hz_XpzFj8jATh1Cg-tMM_ugV=s0",
  //   price: 362.55,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // {
  //   id: 2252358,
  //   title: "Air fryer",
  //   image:
  //     "https://lh3.googleusercontent.com/UmG1hOwale_QCExqIyKS9GPyAlbNyQtWnip5Z5joLRO2_EHKgyQe4jbPohCatuUVxq_jNMnN8xO4K10Be3RlDJ-hlF77ZZjsAmTTnfTFYwlj=s0",
  //   price: 369.41,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // // --5
  // {
  //   id: 2300542,
  //   title: "Aparelho de fondue",
  //   image:
  //     "https://lh3.googleusercontent.com/wOj-QxXAKr4wpJztDvYqLZHfVRvZLEMF095ryE-gNpe5zU2Tevq3hkfQMDU3PTSeIz-up5jfUA7hC9dJmGh89a5YmUJvZV52RBsBoaoiPL3A=s0",
  //   price: 382.04,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // {
  //   id: 135299,
  //   title: "1 mês de almoço garantido em casa",
  //   image:
  //     "https://lh3.googleusercontent.com/phQU8v4wdzb5rsQkb6zt7pKEZUeFtW5-yV6V8paPsVNo79NEhSsGrp5fvayBZ0EfjAPodJSNKgaNwR0Iu3wEgf2E2Txh9QCfRFpSFQgBkTM=s0",
  //   price: 394.59,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  {
    id: 644212,
    title: "Jogo de Taças Maeva 18 peças: Cristal Blumenau",
    image:
      "https://lh3.googleusercontent.com/IlrD6y1C1SFolGDnKYOXJSAbgy6KZo6YREiJCy-Y34CpDVxUy15wRCoT5C3R1Q-fEvcgBcqpN-xl148KYeD0ZaKkfbSRXHjVtcZ0om5JtVY=s0",
    price: 417.48,
    purchased: false,
    status: false,
    buyer: null,
  },
  {
    id: 2346220,
    title: "Edredom dupla face",
    image:
      "https://lh3.googleusercontent.com/8qeecRSpmNjOPYkp2wwKtkuEXYJcUmXLeCf-VjXP6KQAFcM-zErBh0pbEzy4DDFW5FPRlNUMbiQZFPfl4L_eHmB8XGmc-C4weQ11ojv1IDR0=s0",
    price: 428.93,
    purchased: false,
    status: false,
    buyer: null,
  },
  // {
  //   id: 2439,
  //   title: "Home theater CD/DVD",
  //   image:
  //     "https://lh3.googleusercontent.com/WGpTU0rmSKbDp9y_Ym9L_BfZ0_pLP87d2Ikoioz9vX95byiNWKhrO2l91r61_F3re0k1Q3dNs0ZU_hgWjiFY-pxRqnXfdb9l21u0Pp63yNfO=s0",
  //   price: 431.22,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // {
  //   id: 2482477,
  //   title: "Jogo de cama queen: 4 peças",
  //   image:
  //     "https://lh3.googleusercontent.com/wDq6reqQ_6hUbjGSIKn-XppTyH__1PlYrleEUApSiK1wrCf1BQbqE2Ap-kjgWDQe-y3_PzFgHTsY1Lu1Vdx3Oigkx6Kupbkk8uHva7dzZAqMiw=s0",
  //   price: 432.36,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // {
  //   id: 2482479,
  //   title: "Jogo de toalha banhão: 5 peças",
  //   image:
  //     "https://lh3.googleusercontent.com/bg_aMhnW0qbgljm9py602mmTBns1XdO5kov25yeR0Jt2ShFEAqd2sYFrTMkK2APV25ieSiJSVvOgiFOJ5rA3C5X-dYfBg7IJOWJm0lg8UMHu=s0",
  //   price: 457.54,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // {
  //   id: 132507,
  //   title: "Academia para os noivos entrarem em forma depois da lua de mel",
  //   image:
  //     "https://lh3.googleusercontent.com/NryllaURmwwuZql-lrS_FegaVdceonCEijRYCaDNB-VyrdgIq8zPmVIMmmCrhylJrAsCOEMTLsQbkw3X3tVOnOuKngU4ZArxlEnci_X82QL0=s0",
  //   price: 470.13,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  // --6
  {
    id: 2482482,
    title: "Kit cobre leito casal 200 fios: 3 peças",
    image:
      "https://lh3.googleusercontent.com/Sr19l3KEtk67HZX-G8JIShbuVy6pEO93d0tsoNYrhWTNM-pXImih6A0_-y0aU_2bQmdHyb3qByfRPkWaCVyX2vmO1nwSqDaaHusTTBZgEj4=s0",
    price: 477.06,
    purchased: false,
    status: false,
    buyer: null,
  },
  {
    id: 1817348,
    title: "Conjunto de travessas cerâmica: 5 peças",
    image:
      "https://lh3.googleusercontent.com/t8Y4lsRFBVyvHX9QeiSqFJ0tqK0K_VylCUREqXcrgJ1MIXe6rViSdGgHel0UOwMQJWGie1z_k4lN6UiPiJ-lXQOxrNcZOCpaC5ORlSxmoPh8zw=s0",
    price: 588.02,
    purchased: false,
    status: false,
    buyer: null,
  },
  {
    id: 1814843,
    title: "Conjunto panelas Solar Tramontina 6 peças",
    image:
      "https://lh3.googleusercontent.com/OS0pbqJNHeZJ4hWhpnTz6Kj4_lOv3600hDvI1KHgG1BPb3YV6MbMyObyYfs6f4JTKgvFQordfapw-0_kD2c1afi_YyQsyP9nk9df_qrDmUM=s0",
    price: 702.47,
    purchased: false,
    status: false,
    buyer: null,
  },
  {
    id: 250116,
    title: "Cooktop portátil",
    image:
      "https://lh3.googleusercontent.com/I9T5wJwmurE-UILDna3KzXewr76x9n7rJwzck4z-Z7UH0zJ0suQX3fqvi165BMUKSvPcJ5r22AZXL2B5vVk7-MSslTcfL6EsjvLPXpltbEg2=s0",
    price: 708.19,
    purchased: false,
    status: false,
    buyer: null,
  },
  {
    id: 1806900,
    title: "Adega Climatizada",
    image:
      "https://lh3.googleusercontent.com/DY6MXz40D38OXEaTWvN2jUfNwbS-UVigC47IyHEqz0k1DSbhHpkMAoeuba1R0SlpPKWJSwsgi0YI1lsFU973W42zFy5BuSLnbb-yGpm6Ctw=s0",
    price: 760.84,
    purchased: false,
    status: false,
    buyer: null,
  },
  {
    id: 1806909,
    title: "Batedeira",
    image:
      "https://lh3.googleusercontent.com/kHGv5MDT9fK2HmiSbxIFmhmOtRH04uB8x_DVncg9qrsNQ42x3QnuWlEWRtGHOPbcKfEO4jnr-QvolEWnI8mGtmAytLZckDKIfhwpUMQNkdPd=s0",
    price: 818.07,
    purchased: false,
    status: false,
    buyer: null,
  },
  {
    id: 2480377,
    title: "Kit cabeceira e calçadeira cama queen",
    image:
      "https://lh3.googleusercontent.com/s_FKRhBEpV4hYo_eBjMkI0naQVQJe0i2E7f6ihgcI16BEtFfWQ3PCW6Pxjfm1qUINJ_y-TbOZYePwEcfDInz39WdCP5P2omCO8mdjJVU0aXq=s0",
    price: 847.82,
    purchased: false,
    status: false,
    buyer: null,
  },
  {
    id: 1814837,
    title: "Panela 3 em 1 Lyon Tramontina",
    image:
      "https://lh3.googleusercontent.com/rbACTw4DhP4kHGvyidELYn3dZuyriIVL9JyGZ9SLj1nvTKZzhlBqlhHiHA5NMRWlZ69ankA2F4WZ006o43YZ_DBeHuvYb8ph0boa6maK9RWd=s0",
    price: 931.37,
    purchased: false,
    status: false,
    buyer: null,
  },
  // --7
  {
    id: 257865,
    title: "Forno elétrico",
    image:
      "https://lh3.googleusercontent.com/QLc-8LHuzfz-ywEz5mSJa9UhaBT1bXlJl6FDPolCTJu0rMotFuO1ikwR1WJNm6_9MEH4AuKV-2wAT_fHIZjIqyHSU--t5h48nQdZ5271EhE=s0",
    price: 998.93,
    purchased: false,
    status: false,
    buyer: null,
  },
  {
    id: 2492856,
    title: "Guarda-roupa casal espelhado",
    image:
      "https://lh3.googleusercontent.com/dzBnEq4BkdxAmJDST3p7ZxsLwgF75doJRGtoQ5ANRBSHuT_HCGku9RcbXmvPP7cN9vDqnYheUlwvxKpnI5E3iXUMqutE9MqN45nPZ9PdzuE=s0",
    price: 1137.39,
    purchased: false,
    status: false,
    buyer: null,
  },
  {
    id: 86206,
    title: 'TV monitor LCD 26"',
    image:
      "https://lh3.googleusercontent.com/5Mj_NvXkSgUwXo12N2d9j9vkAum9D596jN0rBxV5yUUGtY2is6HhXqvLjOhCBxe5ICWyyetELdjs23lT5K6lIRzZNd23qt0KnqEwI6zKV4hO=s0",
    price: 1149.98,
    purchased: false,
    status: false,
    buyer: null,
  },
  {
    id: 2483740,
    title: "Kit colcha e lençol percal bordado",
    image:
      "https://lh3.googleusercontent.com/ofwGJ4sOPWubaUB6e6RWyRJNxtD5sq8Mf8_5fydZgNlCjmb7TeBqgvMfjSWzHCPXZ0NyTDVVeamQPvH8niRNuBhFenS0nKmWobCdDbtTjvU=s0",
    price: 1333.17,
    purchased: false,
    status: false,
    buyer: null,
  },
  {
    id: 1816330,
    title:
      "Fogão 4 Bocas Brastemp Clean BFS4TARUNA Inox: Timer Acendimento Automático",
    image:
      "https://lh3.googleusercontent.com/V8Dt0g9K1LzabGYw0esxgp4B_QNGBFo2GliwmdnUbS5j-LJttjJ3Tt7G6B-0BXkyJHozt579EQbxyuF68VRed66aWgXk5JdH8LxtcGt3fSBrYg=s0",
    price: 1447.55,
    purchased: false,
    status: false,
    buyer: null,
  },
  {
    id: 2483739,
    title: "Jogo de lençol fio egípcio",
    image:
      "https://lh3.googleusercontent.com/ZlNuIVK2sbtbEgVnqm-iCoXRF8VUH6cVg0DvL_peXKrw-eyLY7uRunEmvhv6VBiY0Tu22OvWzlD5VmoCxhyi1XcTB0z8nLG_zZj1KY5L9SA=s0",
    price: 1515.08,
    purchased: false,
    status: false,
    buyer: null,
  },
  // {
  //   id: 2480930,
  //   title: "Cama queen casal",
  //   image:
  //     "https://lh3.googleusercontent.com/6m0GlLYcbjSqSnmEpdx-4Geyj5XapQ-TBWh4xDDZtFFpYM8cEuHkmeb6meeh_YTimEC8EtZ_zNFRDzu00pkkYrUkKcqW4vZN_umTxIt-IfU=s0",
  //   price: 1653.57,
  //   purchased: false,
  //   status: false,
  //   buyer: null,
  // },
  {
    id: 240363,
    title: "Lava-louça automática",
    image:
      "https://lh3.googleusercontent.com/CQmZNX41mhuck04aHmResVDhy6IG14N4riiXFQ8G4h_jlKzPwTxkPb-7Zbv9w2ItjLX_kjLtmk_-G557lvOx4fWouiLMwOqky5AzksDyku4=s0",
    price: 1941.99,
    purchased: false,
    status: false,
    buyer: null,
  },
  // --8
  {
    id: 352617,
    title: "Você acordou hoje inspirado e resolveu bancar parte da viagem...",
    image:
      "https://lh3.googleusercontent.com/TWXIGKF_7r1Pe_nBsyVAHCktSRENVMORROsgQb6Z8YNavokQ_jV-W2ZlJoKwmHKpiOlBKiHGTAGG1Z8aPQ-87wkkorUogTYkrA0MYn76ISs=s0",
    price: 2305.94,
    purchased: false,
    status: false,
    buyer: null,
  },
  {
    id: 85476,
    title: 'TV 37" HD',
    image:
      "https://lh3.googleusercontent.com/HJLXk23reRIJWAeHJReHx1zTJZEYPxbHSHUbz0EuBppzwYbOGDfEIez9Fdyus4uRbnSD8lg297w1GpS7TY-mRrMKU7VziGyWILR50BhsTUCPDA=s0",
    price: 2785.56,
    purchased: false,
    status: false,
    buyer: null,
  },
  {
    id: 135657,
    title: "Vaquinha para ajudar os noivos quebrados",
    image:
      "https://lh3.googleusercontent.com/a3uSqocgwQXVFDKpoqlUTaqOnYK87NTOw1rBfwXmZs8HZEt6kkqRXe3a8lrNAPES6NjjMsBIC0PRnyygayamvN-gTAYUFJmcmzXscDPl9AA=s0",
    price: 4423.31,
    purchased: false,
    status: false,
    buyer: null,
  },
  {
    id: 368022,
    title: "Bub Sofa 3 Lug C/ Chaise Esq e Pufe (coisa fina!)",
    image:
      "https://lh3.googleusercontent.com/cFraQu3U_ceRpNeHMfaBJR_sMVfSapexwO4RqZ2OW7VlgBVN37Rtd98ISFdfvG-jtLsqcmrlp2j1I97PmLT5_4OhVSoGZM45LBxBUD9VygQ=s0",
    price: 5039.06,
    purchased: false,
    status: false,
    buyer: null,
  },
  {
    id: 238969,
    title: "Lavadora e secadora",
    image:
      "https://lh3.googleusercontent.com/r2n9sehftttZAN6uB2XsNJzTTysNzfDU9-fZCpmQFRSJdZDXZkYG5koXHaAzeHeasJ2cF_zh1elxRXr6LLH9C7c0F6AL7UXofVOUqxtJUnyz5w=s0",
    price: 5681.14,
    purchased: false,
    status: false,
    buyer: null,
  },
  {
    id: 2451165,
    title: "Nossa lavanderia",
    image:
      "https://lh3.googleusercontent.com/5sAPMGDjQ7E8FTG3qLDt77_zFCInNTOa7yUFsDviGZOaoXVIumleQx4vUCEq7ZekGg0CpVQ_lbkCrHshB4oWR6mV65gLaRNjlgFLxpdELy1g=s0",
    price: 6311.77,
    purchased: false,
    status: false,
    buyer: null,
  },
];


function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const productList = shuffle(notShuffledProductList);

export default productList;
