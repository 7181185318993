import React from 'react';
import { Button, Badge, IconButton } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useCart } from './Cart/CartContext';

const CartButton = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  const cartContext = useCart();
  console.log("cartContext", cartContext);
  if (!cartContext) {
    console.error("Cart context is undefined");
    return null;
  }
  
  const { cart, toggleCartDrawer} = cartContext;
  const cartItemCount = cart && cart.length ? cart.length : 0;

  return (
    <>

      {isMobile ? (
        <IconButton onClick={toggleCartDrawer} sx={{ cursor: 'pointer', fontSize: '2rem' }}>
          <Badge badgeContent={cartItemCount} sx={{ fontFamily: "'Roboto', sans-serif", cursor: 'pointer', fontSize: '2rem' }} color="secondary">
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
      ) : (
        
        <Button
          variant="outlined"
          color="inherit"
          startIcon={
            <Badge badgeContent={cartItemCount} sx={{ fontFamily: "'Roboto', sans-serif", cursor: 'pointer', fontSize: '2rem' }} color="secondary">
              <ShoppingCartIcon />
            </Badge>
          }
          onClick={toggleCartDrawer}
        >
          Ver Carrinho
        </Button>
        
      )}
    </>
  );
};

export default CartButton;