import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useMediaQuery, useTheme } from "@mui/material";

const SaveTheDateLink = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
      {isMobile ? (
        <Typography
          variant="h6"
          sx={{
            flexGrow: 1,
            fontFamily: "'Dancing Script', cursive",
            lineHeight: 1.2,
            textAlign: 'center'
          }}
        >
          Save the Date<br />
          Setembro 28, 2024
        </Typography>
      ) : (
        <Typography
          variant="h6"
          sx={{ flexGrow: 1, fontFamily: "'Dancing Script', cursive" }}
        >
          Save the Date - Setembro 28, 2024
        </Typography>
      )}
    </Link>
  );
};

export default SaveTheDateLink;
