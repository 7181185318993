import React from 'react';
import Button from '@mui/material/Button';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Typography from '@mui/material/Typography';
  const FormsButton = () => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  const googleFormsUrl = "https://forms.gle/fWuRdxF39ojv8JXL9";

  return (
    <>
      {/* {isMobile ? (
        <QuestionAnswerIcon 
        onClick={() => window.open(googleFormsUrl, "_blank", "noopener,noreferrer")} 
          sx={{ cursor: 'pointer', fontSize: '2rem' }} // Adjust size if needed
        />
      ) : ( */}
    <br></br>
    <Button
      variant="outlined"
      color="inherit"
      startIcon={<QuestionAnswerIcon />}
      onClick={() => window.open(googleFormsUrl, "_blank", "noopener,noreferrer")}
      sx={{ fontFamily: "'Roboto', sans-serif" }}
    >
      Nos dê sua opinião
    </Button>
    <Typography variant="body2" sx={{ mt: 2 }}>
    Leva apenas 2 minutos!
  </Typography>
      {/* )} */}
    </>
  );
};

export default FormsButton;
