import * as React from "react";
import { AppBar, Toolbar } from '@mui/material';

import Stack from "@mui/material/Stack";
import SaveTheDateLink from "../SaveTheDateLink";
import GiftListButton from "../GiftListButton/GiftListButton";

const Navbar = () => {
  return (
    <AppBar position="static">
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <SaveTheDateLink/>
        <Stack direction="row" spacing={2}>
          <GiftListButton />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
