import React from 'react';
import Button from '@mui/material/Button';
import RedeemIcon from '@mui/icons-material/Redeem';
import { useNavigate } from 'react-router-dom';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';

const GiftListButton = () => {
  const navigate = useNavigate();
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
    {/* {isMobile ? (
      <RedeemIcon 
      onClick={() => navigate('/gifts')}
        sx={{ cursor: 'pointer', fontSize: '2rem' }} // Adjust size if needed
      />
    ) : ( */}
    <Button
      variant="outlined"
      color="inherit"
      startIcon={<RedeemIcon />}
      onClick={() => navigate('/gifts')}
      sx={{ fontFamily: "'Roboto', sans-serif" }}
    >
      Lista de Presentes
      </Button>
      {/* )} */}
    </>
  );
};

export default GiftListButton;
