import React, { createContext, useState, useContext } from 'react';

const PaginationContext = createContext();

export const usePagination = () => useContext(PaginationContext);

export const PaginationProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <PaginationContext.Provider value={{ currentPage, itemsPerPage, handlePageChange }}>
      {children}
    </PaginationContext.Provider>
  );
};
